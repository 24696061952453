<template>
  <div class="relative w-full flex gap-5 items-center" style="height: 36px">
    <!-- left-side fade overlay -->
    <div v-if="!labelVisible && !offsetExceedsOverflow" class="left-fade-overlay" />
    <!-- scroll left nav button -->
    <transition name="fade">
      <button v-if="!labelVisible" class="scroll-nav-btn absolute left-0 top-1/2 transform -translate-y-1/2"
      @click="() => { scrollSuggestions('left') }">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M11.5 13L9.03032 10.5303C8.73745 10.2374 8.73745 9.76255 9.03032 9.46968L11.5 7" 
          stroke="#5E6678" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </transition>
    <!-- Label -->
    <BaseText ref="label" type="body" size="sm" class="text-text-normal cursor-default whitespace-nowrap" 
    :class="{'label': !loading}">
      {{ label }}
    </BaseText>
    <!-- Suggestions list -->
    <div v-if="showSuggestions" ref="suggestions" 
    class="w-full flex items-center gap-2 overflow-x-scroll scrollbar-hide min-w-0 py-1"
    :style="{ paddingLeft: `${labelOffset}px`, paddingRight: `${overflowOffset}px` }">
      <button v-for="(suggestion, index) in suggestions" :key="`suggestion-${index}`"
      class="suggestion flex items-center gap-1.5 pl-1 py-1 pr-2.5 rounded-md bg-white z-10"
      @click="suggestion.action">
        <component v-if="suggestion.icon" :is="suggestion.icon" 
        :style="{color: suggestion.stroke}" class="flex-shrink-0" />
        <img v-else-if="suggestion.image" :src="suggestion.image" class="w-5 h-5" style="border-radius: 4px" />
        <BaseText type="label" size="sm" class="text-text-loud whitespace-nowrap">
          {{ suggestion.name }}
        </BaseText>
      </button>
    </div>
    <!-- Loading state -->
    <div v-else class="flex-grow flex items-center gap-2 overflow-x-hidden py-1">
      <div v-for="n in 5" :key="`skeleton-${n}`" class="skeleton dark w-40 rounded-md h-7" />
    </div>
    <!-- right-side fade overlay -->
    <transition name="overlay">
      <div v-if="loading || (listOverflows && !isMaxScroll)" class="right-fade-overlay" />
    </transition>
    <!-- scroll right nav button -->
    <transition name="fade">
      <button v-if="!loading && listOverflows && !isMaxScroll"
      class="scroll-nav-btn absolute right-0 top-1/2 transform -translate-y-1/2"
      @click="() => { scrollSuggestions('right') }">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M8.5 13L10.9697 10.5303C11.2626 10.2374 11.2626 9.76255 10.9697 9.46968L8.5 7" 
          stroke="#5E6678" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SimilarSuggestionsList',
  props: {
    suggestions: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      computingLabelOffset: true,
      labelOffset: 0,
      overflowOffset: 0,
      offsetExceedsOverflow: false,
      labelVisible: true,
      listOverflows: false,
      isMaxScroll: false
    }
  },
  watch: {
    showSuggestions (newVal) {
      if (!newVal) return
      this.$nextTick(() => {
        this.computeListOverflow()
        this.handleSuggestionsScroll()
        this.$refs.suggestions?.addEventListener('scroll', this.handleSuggestionsScroll)
      })
    }
  },
  computed: {
    showSuggestions () {
      return !this.loading && !this.computingLabelOffset
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeLabelOffset()
      window.addEventListener('resize', this.handleResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    computeLabelOffset () {
      if (this.label.length) {
        const labelRect = this.$refs.label.$el.getBoundingClientRect()
        this.labelOffset = labelRect.width + 20
      }
      this.computingLabelOffset = false
    },
    computeListOverflow () {
      const scroller = this.$refs.suggestions
      if (!scroller) return

      const overflow = Math.max(0, scroller.scrollWidth - scroller.clientWidth)
      this.listOverflows = overflow > 0
      if (overflow > 0 && this.labelOffset > overflow) {
        this.overflowOffset = this.labelOffset - overflow - 36
        this.offsetExceedsOverflow = true
      } else {
        this.overflowOffset = 2
        this.offsetExceedsOverflow = false
      }
    },
    handleResize () {
      this.handleSuggestionsScroll()
      this.computeListOverflow()
    },
    handleSuggestionsScroll (event = null) {
      const scroller = event?.target ?? this.$refs.suggestions
      if (!scroller) return

      this.isMaxScroll = Math.abs(scroller.clientWidth - (scroller.scrollWidth - Math.ceil(scroller.scrollLeft))) <= 2

      // Animate the opacity of the label relative to the scroll
      // Once the user scrolls further than the threshold, the label will be hidden
      if (!event) return
      const label = this.$refs.label.$el
      const labelVisibleThreshold = (this.labelOffset - 20) / 2
      if (scroller.scrollLeft <= labelVisibleThreshold) {
        this.labelVisible = true
        label.style.opacity = Math.max(0, 1 - (scroller.scrollLeft / labelVisibleThreshold))
      } else {
        this.labelVisible = false
        label.style.opacity = 0
      }
    },
    scrollSuggestions (direction) {
      const suggestions = this.$refs.suggestions
      if (!suggestions) return

      // Disable pointer events until the user moves the mouse more than 4px
      // Prevents erroneous clicking when they reach the end of the scroll
      suggestions.style.pointerEvents = 'none'
      let initialMouseX, initialMouseY
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          suggestions.style.pointerEvents = 'auto'
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)

      // Scroll the recent searches
      const distance = Math.max(200, Math.round(this.labelOffset * 1.5))
      if (direction === 'left' && suggestions.scrollLeft - distance < this.labelOffset) {
        // If we scroll back to the label, ensure the entire label is visible
        suggestions.scrollTo({ left: 0, behavior: 'smooth' })
      } else if (direction === 'right' && suggestions.scrollLeft + distance - suggestions.scrollWidth < this.labelOffset) {
        // Scroll to the end if the remaining scroll distance is less than the threshold
        suggestions.scrollTo({ left: suggestions.scrollWidth, behavior: 'smooth' })
      } else {
        const scrollAmount = direction === 'left' ? -distance : distance
        suggestions.scrollBy({ left: scrollAmount, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style scoped>
.suggestion {
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
  transition: box-shadow 100ms ease-in-out;
}
.suggestion:hover {
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.08), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.16), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}
.label {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.right-fade-overlay {
  position: absolute;
  right: 32px;
  top: 0;
  bottom: 0;
  width: 56px;
  background: linear-gradient(to left, #F6F8FA, transparent);
  z-index: 20;
  transform-origin: center right;
}
.right-fade-overlay::after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 34px;
  background-color: #F6F8FA;
}
.left-fade-overlay {
  position: absolute;
  left: 32px;
  top: 0;
  bottom: 0;
  width: 56px;
  background: linear-gradient(to right, #F6F8FA, transparent);
  z-index: 20;
}
.left-fade-overlay::after {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 34px;
  background-color: #F6F8FA;
}
.scroll-nav-btn {
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
  background-color: white;
  z-index: 30;
  transition: box-shadow 100ms ease-in-out;
}
.scroll-nav-btn:hover {
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.08), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.16), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}

/* =============== Vue <transition> classes =============== */
.overlay-enter-active, .overlay-leave-active {
  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
}
.overlay-enter-from, .overlay-enter, .overlay-leave-to {
  transform: scaleX(0);
  opacity: 0.7;
}
.overlay-enter-to, .overlay-leave-from {
  transform: scaleX(1);
  opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 100ms ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>